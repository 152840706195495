.col-icon img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 25px;
}

.col-tags span {
  display: flex;
}

.col-tags {
  width: 20%;
}

.ant-table-cell .col-desc {
  background: #ffffff;
}

.col-desc .desc-div {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: auto;
  height: 4.5em;
  line-height: 1.5em;
}

@media (max-width: 1024px) {
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 4px 6px;
  }

  .col-name {
    max-width: 100px;
    font-weight: unset;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .col-group {
    display: none;
  }

  .col-tags .tags-span {
    display: inline-grid;
  }
}

@media (max-width: 566px) {
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 2px 4px;
  }

  .col-icon img {
    max-width: 32px;
    max-height: 32px;
  }

  .col-tags .tags-span {
    display: inline-grid;
    width: 100%;
  }
}
