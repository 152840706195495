body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ----------- */
.ant-card-head{
  box-shadow: 0px -10px 10px 0px rgba( 0, 0, 0, 0.05 );
}
.ant-card-body, table {
  box-shadow: 0px 10px 10px rgba( 0, 0, 0, 0.05 );
}

.ant-layout-header {
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba( 0, 0, 0, 0.05 );
  text-align: center;
  line-height: 64px;
}
.main-layout {
  min-height: 100vh;
  height: 100%;
}
.layout {
  margin-left: 200px;
  padding: 24px 16px 0;
}

.ant-breadcrumb {
  padding: 16px;
}

.content-div {
  background: #ffffff;
  height: 100%;
}

td.ant-table-column-sort {
  background: #ffffff
}

@media (max-width: 990px) {
  .layout {
    margin-left: 0px;
    padding: 0;
  }
}

@media (max-width: 566px) {
  .ant-layout-header {
    height: 32px;
    line-height: 32px;
  }
  .layout {
    margin-left: 0px;
    padding: 0;
  }
  .ant-breadcrumb {
    padding: 4px 24px;
    text-align: center;
  }
  .content {
    margin: 0;
  }
}
