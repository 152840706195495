.main-card {
  /* box-shadow: 0px; */
  width: 348px;
  height: 348px;
  margin: 0 auto;
}
.main-card .ant-card-body{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.logo {
  width: 300px;
}
.title {
  background: #ffffff;
  text-align: center;
  line-height: 64px;
}

@media (max-width: 566px) {
  .main-card {
    margin-top: 48px;
    width: 300px;
    height: 300px;
  }
  .logo {
    width: 250px;
  }
  .title {
    padding-top: 24px;
    height: 32px;
    line-height: 32px;
  }
}
