.ant-layout-sider-zero-width-trigger {
  top: 0px;
  height: 64px;
  width: 64px;
  line-height: 64px;
  font-size: 32px;
  right: -64px;
  color: #000000;
  background: #f0f2f5;
  box-shadow: 2px 2px 4px #1f1f1f1f;
}

.ant-layout-sider-zero-width-trigger:hover {
  color: #000000;
  background: #ffffff;
}

.sider {
  height: 100vh;
  min-height: 100%;
  z-index: 2;
  position: absolute;
  top: 0; 
  left: 0; 
  background-image: linear-gradient(to bottom, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 0), 
        rgb(240, 242, 245, 1)
    100%);
    border-right: 1px solid #f0f2f5;
    border-bottom: 1px solid #f0f2f5;
}

.menu {
  padding-top: 24px;
}

@media (max-width: 990px) {
  .ant-layout-sider {
    position: absolute;
  }
}
