
h4 {
  padding-top: 16px;
}

.project-top-div {
  background: #f0f2f5;
  height: 100%;
}

.project-card {
  max-width: 1024px;
  margin: 0 auto;
}

.project-image-p {
  text-align: center;
}

.project-image {
  max-width: 100%;
  max-height: 600px;
}

@media (max-width: 992px) {
  .project-image {
    max-height: 500px;
  }
}

@media (max-width: 576px) {
  .project-image {
    max-height: 400px;
  }
}

li {
  padding: 4px;
  line-height: 1.4
}

img {
  box-shadow: 0px 15px 20px rgba( 0, 0, 0, 0.2 );
}

/* .ant-card-body table Styles */

.ant-card-body table {
  margin: 10px 5px 10px;
  box-shadow: 0px 15px 20px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.ant-card-body table td, .ant-card-body table th {
  padding: 8px;
}

.ant-card-body table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.ant-card-body table thead th {
  color: #ffffff;
  background: #00152a;
}


.ant-card-body table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}

.ant-card-body table tr:nth-child(even) {
  background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
  ul {
    padding-inline-start: 20px
  }
  .ant-card-body {
    font-size: 12px;
  }

  .ant-card-body table {
    margin: 5px 0px 5px 0px;
    font-size: 11px;
    white-space: normal;
  }
  
}